import { Link } from "gatsby"
import * as React from "react"
import Container from "../components/Container/Container"
import Seo from "../components/Seo/Seo"

const NotFoundPage = () => (
  <>
  <Container styles={`w-full max-w-full absolute -top-1 h-20 bg-blue mb-1`}></Container>
    <Seo title="404: Not found" />
    <Container styles={`my-48`}>
      <h1>Oeps... <br /> Een 404! <br /> De pagina waarnaar je opzoek bent bestaat niet!</h1>
      <p>Klik <Link to={'/'} className="text-red">hier</Link> om naar de hoofdpagina te gaan of klik <Link to={'/aanbod'} className="text-red">hier</Link> om naar ons actuele aanbod te gaan.</p>
    </Container>
  </>
)

export default NotFoundPage
